import { useState, useEffect } from 'react'
import chevronLeft from '../../assets/chevron-left.svg'
import chevronRight from '../../assets/chevron-right.svg'
import cicleBlack from '../../assets/circle-icon.svg'
import { useSlideOverDialogContext } from './SlideOverDialogContext'
import { useSelector, useDispatch } from 'react-redux'
import { setSelectedItemIds } from '../SideNavigation/SideNavigationSlice'
import VerticalProfile from '../../Components/VerticalProfile/VerticalProfile'
import useTWSMapContext from '../../hooks/UseTWSContext'
import { MenuLabels } from '../../constants/MenuLabels'

type SlideOverDialogProps = {
  selectedSideNav: string
}

const SlideOverDialog = (props: SlideOverDialogProps) => {
  const location = useSelector((state) => state.user.defaultLocation)
  const [isVisible, setIsVisible] = useState(true)
  const dispatch = useDispatch()
  const { addToTWS, removeFromTWS, twsElements } = useTWSMapContext()
  const {
    totalProducts,
    addProductVisibility,
    removeProductVisibility,
    setProductVisibility,
    setTotalProducts,
  } = useSlideOverDialogContext()

  const addVisibility = (productname: string, visible: any) => {
    if (visible) {
      addProductVisibility(productname)
      if (productname === 'NRG' || productname === 'Meteodrone') {
        if (!twsElements.includes('vertical-profile')) {
      //     addToTWS(<VerticalProfile key={'vertical-profile'}></VerticalProfile>)
          dispatch(setSelectedItemIds([MenuLabels.VERTICAL_PROFILE]))
        }
      }
    } else {
      removeProductVisibility(productname)
    }
  }
  const handleButtonClick = () => {
    setIsVisible(!isVisible)
  }
  useEffect(() => {
    setTotalProducts([])
  }, [location])
  return (
    <div className="h-full flex items-center">
      <div className="flex absolute top-1/2">
        <div className="flex">
          <div
            className={` ${
              isVisible ? 'opacity-1 scale-100' : 'hidden opacity-0 scale-0'
            } bg-white w-15rem p-4 `}
          >
            {isVisible && (
              <div>
                <ul>
                  <li>
                    <img
                      className="inline mr-2"
                      src={cicleBlack}
                      alt="Sensor"
                    />
                    <div className="inline w-100 mr-2">Default</div>
                  </li>
                  <hr className="my-3 h-0.5 border-t-0 bg-gray-800 opacity-100 dark:opacity-100" />
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {totalProducts.length == 0 ? (
                        <>
                          <div className="w-24 h-6 animate-pulse rounded bg-gray-500 m-1" />
                          <div className="w-24 h-6 animate-pulse rounded bg-gray-500 m-1" />
                          <div className="w-24 h-6 animate-pulse rounded bg-gray-500 m-1" />
                          <div className="w-24 h-6 animate-pulse rounded bg-gray-500 m-1" />
                        </>
                      ) : (
                        <>
                          {totalProducts.map((item, index) => (
                            <tr key={item + index}>
                              <td>
                                {' '}
                                <input
                                  type="checkbox"
                                  checked={setProductVisibility.includes(item)}
                                  onChange={(event) => {
                                    addVisibility(item, event.target.checked)
                                  }}
                                />
                                &nbsp;&nbsp;
                                <div className="inline-block w-32 mr-2 ">
                                  {item}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </ul>
              </div>
            )}
          </div>
          <div>
            <button
              className="bg-gray-800 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded-tr-2xl rounded-br-2xl md:py-3 md:px-3 lg:py-4 lg:px-4"
              onClick={handleButtonClick}
            >
              <div className="[writing-mode:vertical-lr] flex justify-center items-center flex-row md:flex-col lg:flex-row">
                <span className="tracking-wider text-center p-1 text-xs md:text-sm lg:text-base">
                  {props.selectedSideNav}
                </span>
                <img
                  alt="chevron"
                  src={` ${isVisible ? chevronLeft : chevronRight}`}
                  className="w-4 h-4 md:w-6 md:h-6 lg:w-8 lg:h-8"
                />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SlideOverDialog
