import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import store, { RootState } from '../../store'
import useTWSMapContext from '../../hooks/UseTWSContext'
import CameraNetwork from '../CameraNetwork/CameraNetwork'
import VerticalProfile from '../VerticalProfile/VerticalProfile'
import {
  WMS80MWinds,
  WMSSurfaceWinds,
  WMSTemperature,
  WMSVisibility,
  WMSCeiling,
  WMSSurfaceWindsCA,
  WMS80MWindsCA,
  WMS80MWindsUK,
  WMSSurfaceWindsUK,
  WMSTemperatureUK,
  WMSVisibilityUK,
  WMSCeilingUK,
  WMSWindGust,
  WMSWindGustUK,
  WMSTemperatureCA,
  WMSWindGustCA,
  WMSVisibilityCA,
  WMSCeilingCA,
  WMS80MWindsBR,
  WMSTemperatureBR,
  WMSSurfaceWindsBR,
  WMSVisibilityBR,
  WMSWindGustBR,
  WMSCeilingBR,
  WAFsIcing,
  WAFsTurbulence
} from '../WMSLayerComponents/WMSWeatherLayers'
import { toggleMissionCast } from '../MissionCAST/MissionCASTSlice'
import {
  SatelliteComponent,
  SectionalChartComponent,
} from '../../map/BaseLayers'
import { BaseLayer, setActiveBaseLayer } from '../../map/mapSlice'
import {
  hideAlertSETTING,
  setLocation,
  setLocationType,
  showAlertSETTING,
  showAlertSETTINGCreate,
} from '../Alerting/AlertingSlice/AlertingSlice'
import { toggleRouteCast } from '../DecisionProducts/RouteCast/routecastSlice'
import RouteCAST from '../DecisionProducts/RouteCast/RouteCAST'
import { setCurLayer } from '../WetdogWeatherProducts/WetDogWeatherProductsSlice'
import useErrorStatus from '../../hooks/UseErrorStatus'
import { WetDogWeatherLayersEnum } from '../../interfaces/WetDogWeatherLayers'
import { WMSLayer } from '../WMSLayerComponents/WMSLayer'
import { RouteCASTDraw } from '../DecisionProducts/RouteCast/RouteCASTDraw'
import AddNewLocation from '../../Components/Users/UserLocation/AddNewLocation'
import { VerticalProfileAPI } from '../../services/SurfaceObsAPI'
import {
  deselectMenuItemById,
  setSelectedItems,
} from '../../menus/SideNavigation/SideNavigationSlice'
import { AlertListBox } from '../Alerting/AlertListBox/AlertListBox'
import {  setDefaultLocation } from '../Users/UserSlice'
import AeroxLayer from '../AeroxLayer/AeroxLayer'
import { MenuLabels } from '../../constants/MenuLabels'
import { MissionCAST } from '../MissionCAST/MissionCAST'

// Add other imports for additional components as needed

function MenuSelection() {
  const defaultLocation = useSelector(
    (state: RootState) => state.user.defaultLocation
  )
  const selection = useSelector(
    (state: RootState) => state.sideNav.selectedMenuItem
  )
  const selectedItems = useSelector(
    (state: RootState) => state.sideNav.selectedItems
  )
  const { addToTWS, removeFromTWS, twsElements } = useTWSMapContext()
  const { id } = selection !== null ? selection : { id: null }
  const baseLayerUtils = useSelector(
    (state: RootState) => state.mapState.baseLayerUtil
  )
  const dispatch = useDispatch()
  const [currTile, setCurrTile] = useState<string>('')
  const [currTileName, setCurrTileName] = useState<string>('')
  const currentLocation = useSelector(
    (state: RootState) => state.user.defaultLocation
  )
  const locations = useSelector((state: RootState) => state.user.locations)
  const wmsOpacity = 0.4

  const eventCount = useSelector((state) => state.alert.eventCount)
  const errorStatus = useErrorStatus()
  const user = useSelector(state => state.user)

  const tileProducts = [
    'observations-myradar',
    'observations-mrms-radar',
    // 'decision-missioncast',
    // 'camera-network',
    'aviation-winds-80m',
    'aviation-temperature',
    'aviation-visibility',
    'aviation-surface-winds',
    'aviation-ceiling',
    'aviation-wind-gust',
    'aviation-temperature-ca',
    'aviation-ceiling-ca',
    'aviation-visibility-ca',
    'aviation-windgust-ca',
    'aviation-surface-winds-ca',
    'aviation-winds-80m-ca',
    'aviation-winds-80m-uk',
    'aviation-surface-winds-uk',
    'aviation-temperature-uk',
    'aviation-visibility-uk',
    'aviation-ceiling-uk',
    'aviation-wind-gust-uk',
    'aviation-ceiling-ce',
    'aviation-visibility-ce',
    'aviation-temperature-ce',
    'aviation-surface-winds-ce',
    'aviation-winds-80m-ce',
    'aviation-wind-gust-ce',
    'aviation-temperature-br',
    'aviation-ceiling-br',
    'aviation-visibility-br',
    'aviation-windgust-br',
    'aviation-surface-winds-br',
    'aviation-winds-80m-br',
    'aviation-ceiling-middle-east',
    'aviation-visibility-middle-east',
    'aviation-temperature-middle-east',
    'aviation-surface-winds-middle-east',
    'aviation-winds-80m-middle-east',
    'aviation-wind-gust-middle-east',
    'aviation-wafs-icing',
    'aviation-wafs-turbulence',
    'aerox-menu',
    'add-location'
  ]

  useEffect(() => {
    // the location has changed so remove the camera network layer then add the new one
    if (selectedItems.includes(MenuLabels.CAMERA_NETWORK)) {
      removeFromTWS(MenuLabels.CAMERA_NETWORK)
      addToTWS(
        <CameraNetwork
          key={MenuLabels.CAMERA_NETWORK}
          position={[currentLocation.latitude, currentLocation.longitude]} //[47.925278, -97.0325]
        />
      )
    }

    if (user.isVerified && user.isActive) {
      const vprof = VerticalProfileAPI(currentLocation)
      vprof.then((data) => {
        if (data.features.length > 0) {
          dispatch(setSelectedItems([MenuLabels.OBSERVATIONS, MenuLabels.VERTICAL_PROFILE]))
        }
      }).catch(err => {
        //do nothing
        console.log(err)
      })

    }
  }, [currentLocation])

  useEffect(() => {
    const newActiveLayer = baseLayerUtils[1]
    const oldActiveLayer = baseLayerUtils[0]
    switch (newActiveLayer) {
      case MenuLabels.MAPS_ROADS:
        if (oldActiveLayer === MenuLabels.MAPS_SATELLITE) {
          removeFromTWS(MenuLabels.MAPS_SATELLITE)
          dispatch(setActiveBaseLayer(MenuLabels.MAPS_ROADS as BaseLayer))
        } else if (oldActiveLayer === MenuLabels.MAPS_SECTIONAL) {
          removeFromTWS(MenuLabels.MAPS_SECTIONAL)
          dispatch(setActiveBaseLayer(MenuLabels.MAPS_ROADS as BaseLayer))
        }
        break
      case MenuLabels.MAPS_SATELLITE:
        if (oldActiveLayer === MenuLabels.MAPS_ROADS ) {
          dispatch(setActiveBaseLayer(MenuLabels.MAPS_SATELLITE as BaseLayer))
        } else if (oldActiveLayer === MenuLabels.MAPS_SECTIONAL) {
          removeFromTWS(MenuLabels.MAPS_SECTIONAL)
          dispatch(setActiveBaseLayer(MenuLabels.MAPS_SATELLITE as BaseLayer))
        }
        addToTWS(
          <SatelliteComponent
            url={
              'https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryTopo/MapServer/tile/{z}/{y}/{x}'
            }
            attribution=""
            key={MenuLabels.MAPS_SATELLITE}
            opacity={0.6}
          />
        )
        break
      case MenuLabels.MAPS_SECTIONAL:
        if (oldActiveLayer === MenuLabels.MAPS_ROADS ) {
          dispatch(setActiveBaseLayer(MenuLabels.MAPS_SECTIONAL as BaseLayer))
        } else if (oldActiveLayer === 'maps-satellite') {
          removeFromTWS('maps-satellite')
          dispatch(setActiveBaseLayer(MenuLabels.MAPS_SECTIONAL as BaseLayer))
        }
        addToTWS(<SectionalChartComponent key={MenuLabels.MAPS_SECTIONAL} />)
        break
      default:
        break
    }
  }, [baseLayerUtils])

  const persistantProducts = [
    // these items need to not change when switching between
    // normal and dark mode.
    'observations-myradar',
    'observations-mrms-radar',
    'aviation-ceiling',
    'aviation-visibility',
    'aviation-temperature',
    'aviation-surface-winds',
    'aviation-winds-80m',
    'aviation-wind-gust',
    'aviation-temperature-ca',
    'aviation-ceiling-ca',
    'aviation-visibility-ca',
    'aviation-windgust-ca',
    'aviation-surface-winds-ca',
    'aviation-winds-80m-ca',
    'aviation-surface-winds-uk',
    'aviation-winds-80m-uk',
    'aviation-temperature-uk',
    'aviation-visibility-uk',
    'aviation-ceiling-uk',
    'aviation-wind-gust-uk',
    'forecast-aviation-ceiling',
    'forecast-aviation-visibility',
    'forecast-aviation-temperature',
    'forecast-aviation-surface-winds',
    'forecast-aviation-winds-80m',
    'forecast-aviation-wind-gust',
    'aviation-ceiling-ce',
    'aviation-visibility-ce',
    'aviation-temperature-ce',
    'aviation-surface-winds-ce',
    'aviation-winds-80m-ce',
    'aviation-wind-gust-ce',
    'aviation-temperature-br',
    'aviation-ceiling-br',
    'aviation-visibility-br',
    'aviation-windgust-br',
    'aviation-surface-winds-br',
    'aviation-winds-80m-br',
    'aviation-ceiling-middle-east',
    'aviation-visibility-middle-east',
    'aviation-temperature-middle-east',
    'aviation-surface-winds-middle-east',
    'aviation-winds-80m-middle-east',
    'aviation-wind-gust-middle-east',
    'aviation-wafs-icing',
    'aviation-wafs-turbulence',
    'aerox-menu'
  ]

  useEffect(() => {
    if (  
      id == currTile || id == null &&
      !selectedItems.includes(currTileName)) {
      /**
       * HH - adding checks for weather viz layers so
       * they don't get removed when we want to keep them
       */
      if (Array.isArray(selectedItems)) {
        if (
          !selectedItems.some((element) =>
            persistantProducts.includes(element)
          )
        ) {
          dispatch(
            setCurLayer({
              layer: -1,
              mode: 'forecast',
            })
          )
          removeFromTWS(currTile)
          setCurrTile('')
          setCurrTileName('')
        }
        if (currTile === 'aerox-menu') {
            removeFromTWS('aerox')
        }
      }
      if(selectedItems.includes(MenuLabels.VERTICAL_PROFILE) && !(MenuLabels.VERTICAL_PROFILE in twsElements) ){
        addToTWS(<VerticalProfile key={MenuLabels.VERTICAL_PROFILE}></VerticalProfile>)
      }  

      return
    }

    if (tileProducts.includes(id) && currTile != '') {
      dispatch(
        setCurLayer({
          layer: -1,
          mode: '',
        })
      )
      removeFromTWS(currTile)
      dispatch(deselectMenuItemById(currTile))
    }

    const sel: string = selection !== null ? selection.id : ''
    if (sel.startsWith('maps-')) {
      // processMapSelection(selection.id)
    }


    // Assert that id is a string (since we've already checked that it's not null or undefined)
    const idString = id as string
    // if(idString !== null) {
    //   if (idString.includes('locations-')) { // make sure to restore savedView location
    //     const locationItem = locations.find(location => location.name === idString.substring(10))
    //     if (locationItem !== undefined) {
    //       dispatch(setDefaultLocation(locationItem))
    //     } else {
    //       errorStatus.addMessage('Error: Location, "' + idString.substring(10) + '", not found.', 200, 'error', false)
    //     }
    //   }
    // }

    switch (idString) {
      case MenuLabels.CAMERA_NETWORK:
        if (selectedItems.includes(MenuLabels.CAMERA_NETWORK)) {
          addToTWS(
            <CameraNetwork
              key={MenuLabels.CAMERA_NETWORK}
              position={[defaultLocation.latitude, defaultLocation.longitude]} //[47.925278, -97.0325]
            />
          )
        }
        break
      case MenuLabels.WINDS_80M:
        // addToTWS(<WMS80MWinds key={'aviation-winds-80m'} opacity={0.85} />)
        dispatch(
          setCurLayer({
            mode: 'current',
            layer: WetDogWeatherLayersEnum.CURRENT_WIND80M,
          })
        )
        break
      // Add more cases for other components here
      case MenuLabels.TEMPERATURE:
        // addToTWS(<WMSTemperature key={'aviation-temperature'} opacity={0.45} />)
        dispatch(
          setCurLayer({
            mode: 'current',
            layer: WetDogWeatherLayersEnum.CURRENT_TEMPERATURE,
          })
        )
        break
      case MenuLabels.VISIBILITY:
        // addToTWS(<WMSVisibility key={'aviation-visibility'} opacity={0.45} />)
        dispatch(
          setCurLayer({
            mode: 'current',
            layer: WetDogWeatherLayersEnum.CURRENT_CLOUDCOVER,
          })
        )
        break
      case MenuLabels.SURFACE_WINDS:
        // addToTWS(
        //   <WMSSurfaceWinds key={'aviation-surface-winds'} opacity={0.85} />
        // )
        dispatch(
          setCurLayer({
            mode: 'current',
            layer: WetDogWeatherLayersEnum.CURRENT_WINDUV,
          })
        )
        break
      case MenuLabels.CEILING:
        // addToTWS(<WMSCeiling key={'aviation-ceiling'} opacity={0.45} />)
        dispatch(
          setCurLayer({
            mode: 'current',
            layer: WetDogWeatherLayersEnum.CURRENT_CLOUDCEILING,
          })
        )
        break
      case MenuLabels.WIND_GUST:
        dispatch(
          setCurLayer({
            mode: 'current',
            layer: WetDogWeatherLayersEnum.CURRENT_WINDGUST,
          })
        )
        break
      case MenuLabels.FORECAST_WINDS_80M:
        // addToTWS(<WMS80MWinds key={'aviation-winds-80m'} opacity={0.85} />)
        dispatch(
          setCurLayer({
            mode: 'forecast',
            layer: WetDogWeatherLayersEnum.WIND80M,
          })
        )
        break
      // Add more cases for other components here
      case MenuLabels.FORECAST_TEMPERATURE:
        // addToTWS(<WMSTemperature key={'aviation-temperature'} opacity={0.35} />)
        dispatch(
          setCurLayer({
            mode: 'forecast',
            layer: WetDogWeatherLayersEnum.TEMPERATURE,
          })
        )
        break
      case MenuLabels.FORECAST_VISIBILITY:
        dispatch(
          setCurLayer({
            mode: 'forecast',
            layer: WetDogWeatherLayersEnum.CLOUDCOVER,
          })
        )
        break
      case MenuLabels.FORECAST_SURFACE_WINDS:
        dispatch(
          setCurLayer({
            mode: 'forecast',
            layer: WetDogWeatherLayersEnum.WINDUV,
          })
        )
        break
      case MenuLabels.FORECAST_CEILING:
        dispatch(
          setCurLayer({
            mode: 'forecast',
            layer: WetDogWeatherLayersEnum.CLOUDCEILING,
          })
        )
        break
      case MenuLabels.SURFACE_WINDS_CANADA:
        addToTWS(
          <WMSSurfaceWindsCA
            key={MenuLabels.SURFACE_WINDS_CANADA}
            opacity={wmsOpacity}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.WINDS_80M_CANADA:
        addToTWS(
          <WMS80MWindsCA key={MenuLabels.WINDS_80M_CANADA} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.TEMPERATURE_CANADA:
        addToTWS(
          <WMSTemperatureCA
            key={MenuLabels.TEMPERATURE_CANADA}
            opacity={wmsOpacity}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.CEILING_CANADA:
        addToTWS(
          <WMSCeilingCA key={MenuLabels.CEILING_CANADA} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.VISIBILITY_CANADA:
        addToTWS(
          <WMSVisibilityCA
            key={MenuLabels.VISIBILITY_CANADA}
            opacity={wmsOpacity}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.WIND_GUST_CANADA:
        addToTWS(
          <WMSWindGustCA key={MenuLabels.WIND_GUST_CANADA} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.SURFACE_WINDS_BR:
        addToTWS(
          <WMSSurfaceWindsBR
            key={MenuLabels.SURFACE_WINDS_BR}
            opacity={wmsOpacity}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.WINDS_80M_BR:
        addToTWS(
          <WMS80MWindsBR key={MenuLabels.WINDS_80M_BR} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.TEMPERATURE_BR:
        addToTWS(
          <WMSTemperatureBR
            key={MenuLabels.TEMPERATURE_BR}
            opacity={wmsOpacity}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.CEILING_BR:
        addToTWS(
          <WMSCeilingBR key={MenuLabels.CEILING_BR} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.VISIBILITY_BR:
        addToTWS(
          <WMSVisibilityBR
            key={MenuLabels.VISIBILITY_BR}
            opacity={wmsOpacity}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.WIND_GUST_BR:
        addToTWS(
          <WMSWindGustBR key={MenuLabels.WIND_GUST_BR} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.WINDS_80M_WESTERN_EUROPE:
        addToTWS(
          <WMS80MWindsUK key={MenuLabels.WINDS_80M_WESTERN_EUROPE} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.SURFACE_WINDS_WESTERN_EUROPE:
        addToTWS(
          <WMSSurfaceWindsUK
            key={MenuLabels.SURFACE_WINDS_WESTERN_EUROPE}
            opacity={wmsOpacity}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.TEMPERATURE_WESTERN_EUROPE:
        addToTWS(
          <WMSTemperatureUK
            key={MenuLabels.TEMPERATURE_WESTERN_EUROPE}
            opacity={wmsOpacity}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.VISIBILITY_WESTERN_EUROPE:
        addToTWS(
          <WMSVisibilityUK
            key={MenuLabels.VISIBILITY_WESTERN_EUROPE}
            opacity={wmsOpacity}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.CEILING_WESTERN_EUROPE:
        addToTWS(
          <WMSCeilingUK key={MenuLabels.CEILING_WESTERN_EUROPE} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.WIND_GUST_WESTERN_EUROPE:
        addToTWS(<WMSWindGustUK key={MenuLabels.WIND_GUST_WESTERN_EUROPE} opacity={0.4} />)
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.CEILING_CENTRAL_EUROPE:
        addToTWS(
          <WMSLayer
            key={MenuLabels.CEILING_CENTRAL_EUROPE}
            layerName={'Cloud Ceiling (CE)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.VISIBILITY_CENTRAL_EUROPE:
        addToTWS(
          <WMSLayer
            key={MenuLabels.VISIBILITY_CENTRAL_EUROPE}
            layerName={'Visibility (CE)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.TEMPERATURE_CENTRAL_EUROPE:
        addToTWS(
          <WMSLayer
            key={MenuLabels.TEMPERATURE_CENTRAL_EUROPE}
            layerName={'Temperature (CE)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.SURFACE_WINDS_CENTRAL_EUROPE:
        addToTWS(
          <WMSLayer
            key={MenuLabels.SURFACE_WINDS_CENTRAL_EUROPE}
            layerName={'Surface Winds (CE)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.WINDS_80M_CENTRAL_EUROPE:
        addToTWS(
          <WMSLayer
            key={MenuLabels.WINDS_80M_CENTRAL_EUROPE}
            layerName={'80m Winds (CE)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.WIND_GUST_MIDDLE_EAST:
        addToTWS(
          <WMSLayer
            key={MenuLabels.WIND_GUST_MIDDLE_EAST}
            layerName={'Wind Gust (CE)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.CEILING_MIDDLE_EAST:
        addToTWS(
          <WMSLayer
            key={MenuLabels.CEILING_MIDDLE_EAST}
            layerName={'Cloud Ceiling (Middle East)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.VISIBILITY_MIDDLE_EAST:
        addToTWS(
          <WMSLayer
            key={MenuLabels.VISIBILITY_MIDDLE_EAST}
            layerName={'Visibility (Middle East)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.TEMPERATURE_MIDDLE_EAST:
        addToTWS(
          <WMSLayer
            key={MenuLabels.TEMPERATURE_MIDDLE_EAST}
            layerName={'Temperature (Middle East)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.SURFACE_WINDS_MIDDLE_EAST:
        addToTWS(
          <WMSLayer
            key={MenuLabels.SURFACE_WINDS_MIDDLE_EAST}
            layerName={'Surface Winds (Middle East)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.WINDS_80M_MIDDLE_EAST:
        addToTWS(
          <WMSLayer
            key={MenuLabels.WINDS_80M_MIDDLE_EAST}
            layerName={'80m Winds (Middle East)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.WIND_GUST_MIDDLE_EAST:
        addToTWS(
          <WMSLayer
            key={MenuLabels.WIND_GUST_MIDDLE_EAST}
            layerName={'Wind Gust (Middle East)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
        case MenuLabels.ICING:
          addToTWS(
            <WAFsIcing
              key={MenuLabels.ICING}
              opacity={0.4}
            />
          )
          dispatch(toggleMissionCast(false))
          break
        case MenuLabels.TURBULENCE:
          addToTWS(
            <WAFsTurbulence
              key={MenuLabels.TURBULENCE}
              opacity={0.4}
            />
          )
          dispatch(toggleMissionCast(false))
          break
        
      case MenuLabels.MISSIONCAST:
        dispatch(toggleMissionCast(true))
        break
      case MenuLabels.ALERTS_CREATE:
        if (
          selectedItems.includes(MenuLabels.ALERTS_CREATE) &&
          !store.getState().sideNav.menuItems[0].subMenu[1].disabled
        ) {
          if (eventCount < 3) {
            dispatch(showAlertSETTING())
            dispatch(setLocation(''))
            dispatch(setLocationType(''))
            dispatch(showAlertSETTINGCreate(true))
          } else {
            errorStatus.addMessage(
              'Event limit reached (Max limit - 3)',
              404,
              'error',
              false
            )
          }
        }
        dispatch(toggleRouteCast(false))
        break
      case MenuLabels.MRMS_RADAR:
        dispatch(
          setCurLayer({
            layer: WetDogWeatherLayersEnum.MRMS,
            mode: 'forecast',
          })
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.MYRADAR:
        dispatch(
          setCurLayer({
            layer: WetDogWeatherLayersEnum.RADAR,
            mode: 'forecast',
          })
        )
        dispatch(toggleMissionCast(false))
        break
      case MenuLabels.ROUTECAST:
        // dispatch(toggleRouteCast(true))
        dispatch(hideAlertSETTING())
        addToTWS(
          <div
            className="absolute top-0 left-0 w-full"
            key={MenuLabels.ROUTECAST}
          >
            <RouteCAST />
            <RouteCASTDraw selectedSideNav={MenuLabels.ROUTECAST} />
          </div>
        )
        break
      case 'forecast-aviation-wind-gust':
        dispatch(
          setCurLayer({
            layer: WetDogWeatherLayersEnum.WINDGUST,
            mode: 'forecast',
          })
        )
        break
      case MenuLabels.ALERTS_MENU:
        addToTWS(<AlertListBox key={MenuLabels.ALERTS_MENU} />)
        break
      case 'aerox-menu':
        addToTWS(<AeroxLayer key="aerox" />)
        break
      default:
        break
    }
    if (tileProducts.includes(id)) {
      setCurrTile(id)
      setCurrTileName(selection?.label)
      // dispatch(setActiveBaseLayer('maps-road')) //
    }
  }, [id, selectedItems])

  return null
}

export default MenuSelection
