import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store"
import useTWSMapContext from "../../hooks/UseTWSContext"
import { setCurLayer } from "../WetdogWeatherProducts/WetDogWeatherProductsSlice"
import { setDefaultLocation } from "../Users/UserSlice"
import { changBaseLayers } from "../../map/mapSlice"
import useErrorStatus from "../../hooks/UseErrorStatus"
import CameraNetwork from "../CameraNetwork/CameraNetwork"
import { WetDogWeatherLayersEnum } from "../../interfaces/WetDogWeatherLayers"               //"../../../src/interfaces/WetDogWeatherLayers.ts"
import { 
            WMS80MWindsCA, 
            WMS80MWindsUK,
            WMSCeilingUK,
            WMSSurfaceWindsCA,
            WMSSurfaceWindsUK,
            WMSVisibilityUK,
            WMSTemperatureUK,
            WMSWindGustUK
 } from "../WMSLayerComponents/WMSWeatherLayers"
 import { WMSLayer } from "../WMSLayerComponents/WMSLayer"
 import { toggleMissionCast } from "../MissionCAST/MissionCASTSlice"

const RestoreSelection = () => {

  const selectedItemIds = useSelector((state: RootState) => state.sideNav.selectedItems)
  const { addToTWS, removeFromTWS} = useTWSMapContext()
  const dispatch = useDispatch()
  const errorStatus = useErrorStatus()
  const locations = useSelector((state: RootState) => state.user.locations)
  const defaultLocation = useSelector((state: RootState) => state.user.defaultLocation)

  const activateSelectedProducts = (itemsArray: string[]) => {
    const wmsOpacity = 0.4
    // need to remove products not in the selectedItemIds array
    if (selectedItemIds.includes('camera-network')) {
      removeFromTWS('camera-network')
    }

    // selectedItemsArray = 
    // forza bruta remove all the layers before adding the new ones 
    dispatch(setCurLayer({mode:'', layer: -1}))
    removeFromTWS('camera-network')
    removeFromTWS('aviation-surface-winds-ca')
    removeFromTWS('aviation-winds-80m-ca')
    removeFromTWS('aviation-surface-winds-uk')
    removeFromTWS('aviation-winds-80m-uk')
    removeFromTWS('aviation-ceiling-uk')
    removeFromTWS('aviation-visibility-uk')
    removeFromTWS('aviation-temperature-uk')
    removeFromTWS('aviation-wind-gust-uk')
    removeFromTWS('aviation-ceiling-ce')
    removeFromTWS('aviation-visibility-ce')
    removeFromTWS('aviation-temperature-ce')
    removeFromTWS('aviation-surface-winds-ce')
    removeFromTWS('aviation-winds-80m-ce')
    removeFromTWS('aviation-wind-gust-ce')
    removeFromTWS('aviation-ceiling-middle-east')
    removeFromTWS('aviation-visibility-middle-east')
    removeFromTWS('aviation-temperature-middle-east')
    removeFromTWS('aviation-surface-winds-middle-east')
    removeFromTWS('aviation-winds-80m-middle-east')

    // now add the selected products
    itemsArray.forEach((itemId) => {

    if (itemId.includes('locations-')) { // make sure to restore savedView location
      const locationItem = locations.find(location => location.name === itemId.substring(10))
      if (locationItem !== undefined) {
        dispatch(setDefaultLocation(locationItem))
      } else {
        errorStatus.addMessage('Error: Location, "' + itemId.substring(10) + '", not found.', 200, 'error', false)
      }
    }
    // maps-road is the default base layer
    if (!itemsArray.includes('maps-satellite') &&
      !itemsArray.includes('maps-sectional') &&
      !itemsArray.includes('maps-road')) {
        dispatch(changBaseLayers('maps-road'))
      }

    if( itemId.includes('maps-') && itemId !== 'maps-menu') {
      dispatch(changBaseLayers(itemId))
    }

    switch (itemId) {
      case 'decision-missioncast':
        if (selectedItemIds.includes('decision-missioncast')) {
          dispatch(toggleMissionCast(true))
        }
        break;
      case 'maps-road':
        if (selectedItemIds.includes('maps-road')) {
          dispatch(changBaseLayers(itemId))
        }
        break;
      case 'maps-satellite':
        if (selectedItemIds.includes('maps-satellite')) {
          dispatch(changBaseLayers(itemId))
        }
        break;
      case 'maps-sectional':
        if (selectedItemIds.includes('maps-sectional')) {
          dispatch(changBaseLayers(itemId))
        }
        break;
      case 'camera-network':
        if (selectedItemIds.includes('camera-network')) {
          // removeFromTWS('camera-network')
          addToTWS(
            <CameraNetwork
              key={'camera-network'}
              position={[defaultLocation.latitude, defaultLocation.longitude]} //[47.925278, -97.0325]
            />
          )
        }
        break
      case 'aviation-winds-80m':
        dispatch(
          setCurLayer({
            mode: 'current',
            layer: WetDogWeatherLayersEnum.CURRENT_WIND80M,
          })
        )
        break
      case 'aviation-temperature':
        dispatch(
          setCurLayer({
            mode: 'current',
            layer: WetDogWeatherLayersEnum.CURRENT_TEMPERATURE,
          })
        )
        break
      case 'aviation-visibility':
        dispatch(
          setCurLayer({
            mode: 'current',
            layer: WetDogWeatherLayersEnum.CURRENT_CLOUDCOVER,
          })
        )
        break
      case 'aviation-surface-winds':
        dispatch(
          setCurLayer({
            mode: 'current',
            layer: WetDogWeatherLayersEnum.CURRENT_WINDUV,
          })
        )
        break
      case 'aviation-ceiling':
        dispatch(
          setCurLayer({
            mode: 'current',
            layer: WetDogWeatherLayersEnum.CURRENT_CLOUDCEILING,
          })
        )
        break
      case 'aviation-wind-gust':
        dispatch(
          setCurLayer({
            mode: 'current',
            layer: WetDogWeatherLayersEnum.CURRENT_WINDGUST,
          })
        )
        break
      case 'forecast-aviation-winds-80m':
        dispatch(
          setCurLayer({
            mode: 'forecast',
            layer: WetDogWeatherLayersEnum.WIND80M,
          })
        )
        break
      case 'forecast-aviation-winds-80m':
        // addToTWS(<WMS80MWinds key={'aviation-winds-80m'} opacity={0.85} />)
        dispatch(
          setCurLayer({
            mode: 'forecast',
            layer: WetDogWeatherLayersEnum.WIND80M,
          })
        )
        break
      // Add more cases for other components here
      case 'forecast-aviation-temperature':
        // addToTWS(<WMSTemperature key={'aviation-temperature'} opacity={0.35} />)
        dispatch(
          setCurLayer({
            mode: 'forecast',
            layer: WetDogWeatherLayersEnum.TEMPERATURE,
          })
        )
        break
      case 'forecast-aviation-visibility':
        dispatch(
          setCurLayer({
            mode: 'forecast',
            layer: WetDogWeatherLayersEnum.CLOUDCOVER,
          })
        )
        break
      case 'forecast-aviation-surface-winds':
        dispatch(
          setCurLayer({
            mode: 'forecast',
            layer: WetDogWeatherLayersEnum.WINDUV,
          })
        )
        break
      case 'forecast-aviation-ceiling':
        dispatch(
          setCurLayer({
            mode: 'forecast',
            layer: WetDogWeatherLayersEnum.CLOUDCEILING,
          })
        )
        break
      case 'aviation-surface-winds-ca':
        addToTWS(
          <WMSSurfaceWindsCA
            key={'aviation-surface-winds-ca'}
            opacity={wmsOpacity}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-winds-80m-ca':
        addToTWS(
          <WMS80MWindsCA key={'aviation-winds-80m-ca'} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-winds-80m-uk':
        addToTWS(
          <WMS80MWindsUK key={'aviation-winds-80m-uk'} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-surface-winds-uk':
        addToTWS(
          <WMSSurfaceWindsUK
            key={'aviation-surface-winds-uk'}
            opacity={wmsOpacity}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-temperature-uk':
        addToTWS(
          <WMSTemperatureUK
            key={'aviation-temperature-uk'}
            opacity={wmsOpacity}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-visibility-uk':
        addToTWS(
          <WMSVisibilityUK
            key={'aviation-visibility-uk'}
            opacity={wmsOpacity}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-ceiling-uk':
        addToTWS(
          <WMSCeilingUK key={'aviation-ceiling-uk'} opacity={wmsOpacity} />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-wind-gust-uk':
        addToTWS(<WMSWindGustUK key={'aviation-wind-gust-uk'} opacity={0.4} />)
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-ceiling-ce':
        addToTWS(
          <WMSLayer
            key={'aviation-ceiling-ce'}
            layerName={'Cloud Ceiling (CE)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-visibility-ce':
        addToTWS(
          <WMSLayer
            key={'aviation-visibility-ce'}
            layerName={'Visibility (CE)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-temperature-ce':
        addToTWS(
          <WMSLayer
            key={'aviation-temperature-ce'}
            layerName={'Temperature (CE)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-surface-winds-ce':
        addToTWS(
          <WMSLayer
            key={'aviation-surface-winds-ce'}
            layerName={'Surface Winds (CE)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-winds-80m-ce':
        addToTWS(
          <WMSLayer
            key={'aviation-winds-80m-ce'}
            layerName={'80m Winds (CE)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-wind-gust-ce':
        addToTWS(
          <WMSLayer
            key={'aviation-wind-gust-ce'}
            layerName={'Wind Gust (CE)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-ceiling-middle-east':
        addToTWS(
          <WMSLayer
            key={'aviation-ceiling-middle-east'}
            layerName={'Cloud Ceiling (Middle East)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-visibility-middle-east':
        addToTWS(
          <WMSLayer
            key={'aviation-visibility-middle-east'}
            layerName={'Visibility (Middle East)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-temperature-middle-east':
        addToTWS(
          <WMSLayer
            key={'aviation-temperature-middle-east'}
            layerName={'Temperature (Middle East)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-surface-winds-middle-east':
        addToTWS(
          <WMSLayer
            key={'aviation-surface-winds-middle-east'}
            layerName={'Surface Winds (Middle East)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-winds-80m-middle-east':
        addToTWS(
          <WMSLayer
            key={'aviation-winds-80m-middle-east'}
            layerName={'80m Winds (Middle East)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'aviation-wind-gust-middle-east':
        addToTWS(
          <WMSLayer
            key={'aviation-wind-gust-middle-east'}
            layerName={'Wind Gust (Middle East)'}
            opacity={0.4}
          />
        )
        dispatch(toggleMissionCast(false))
        break
      case 'decision-missioncast':
        dispatch(toggleMissionCast(true))
        break
      case 'alerts-create':
        if (
          selectedItemIds.includes('alerts-create') &&
          !store.getState().sideNav.menuItems[0].subMenu[1].disabled
        ) {
          if (eventCount < 3) {
            dispatch(showAlertSETTING())
            dispatch(setLocation(''))
            dispatch(setLocationType(''))
            dispatch(showAlertSETTINGCreate(true))
          } else {
            errorStatus.addMessage(
              'Event limit reached (Max limit - 3)',
              404,
              'error',
              false
            )
          }
        }
        dispatch(toggleRouteCast(false))
        break
      case 'add-location':
        dispatch(showAddLocationPopup())
        break
      case 'vertical-profile':
        addToTWS(<VerticalProfile key={idString}></VerticalProfile>)
        break
      case 'observations-mrms-radar':
        dispatch(
          setCurLayer({
            layer: WetDogWeatherLayersEnum.MRMS,
            mode: 'forecast',
          })
        )
        dispatch(toggleMissionCast(false))
        break
      case 'observations-myradar':
        dispatch(
          setCurLayer({
            layer: WetDogWeatherLayersEnum.RADAR,
            mode: 'forecast',
          })
        )
        dispatch(toggleMissionCast(false))
        break
      case 'decision-routcase':
        dispatch(hideAlertSETTING())
        addToTWS(
          <div
            className="absolute top-0 left-0 w-full"
            key={'decision-routcast'}
          >
            <RouteCAST />
          </div>
        )
        break
      case 'aviation-wind-gust':
        // addToTWS(<WMSWindGust key={idString} opacity={wmsOpacity} />)
        // dispatch(toggleMissionCast(false))
        dispatch(
          setCurLayer({
            layer: WetDogWeatherLayersEnum.WINDGUST,
            mode: 'current',
          })
        )
        break
      case 'forecast-aviation-wind-gust':
        dispatch(
          setCurLayer({
            layer: WetDogWeatherLayersEnum.WINDGUST,
            mode: 'forecast',
          })
        )
        break
      default:
        break
    }

  })
}
return { activateSelectedProducts}
}

export  default RestoreSelection