/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ReactElement,
  useRef,
  useState,
  useEffect,
  SyntheticEvent,
} from 'react'
import {
  inputFieldStyles,
  labelStyles,
  popupContainerStyles,
  inputFieldContainerStyles,
  buttonContainerStyles,
  buttonStyles,
  disabledButtonStyles,
} from './popupStyles'
import { Marker, Popup } from 'react-leaflet'
import {
  addNewUserLocation,
  hideAddLocationPopup,
  setDefaultLocation,
} from '../UserSlice'
import { useDispatch, useSelector } from 'react-redux'
import store, { RootState } from '../../../store'
import {
  appendLocationMenu,
  appendMenuItem,
  clearSelectedItems,
  deselectMenuItemById,
  prependMenuItem,
  selectedItemIds,
  selectedItems,
  setSelectedItemIds,
} from '../../../menus/SideNavigation/SideNavigationSlice'
import marker from '../../../assets/marker.svg'

import { DeleteLocation } from '../../../App'
import { IMenuItem } from '../../../menus/SideNavigation/SideNavItems'
import useErrorStatus from '../../../hooks/UseErrorStatus'
import { useAuth0 } from '@auth0/auth0-react'

export default function LocationPopup({ position }: any): ReactElement {
  const errorStatus = useErrorStatus()
  const [userLocation, setUserLocation] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const markerRef = useRef<L.Marker | null>(null)
  const defaultLocation = useSelector(
    (state: RootState) => state.user.defaultLocation
  )

  const invalidLocations = [
    'Location',
    'Add new location',
    'Alerts',
    'Create an Alert',
    'Events List',
    'Wind Speed',
    'Surface Winds',
    'Wind Gust',
    'Visibility',
    'Ceiling',
    'Precipitation',
    'Temperature',
    //'Lightning',
    'NWS Alerts',
    'Decision Products',
    'MissionCast',
    'RouteCast',
    'Observations',
    'MyRadar',
    'Surface Observations',
    'US',
    'Canada',
    'UK',
    'Current Area Weather',
    'US',
    'Cloud Ceiling',
    'Visibility',
    'Temperature',
    'Surface Winds',
    '80m Winds',
    'Canada',
    'Surface Winds (CA)',
    'Temperature (CA)',
    'Ceiling (CA)',
    'Visibility (CA)',
    'Wind Gust (CA)',
    '80m Winds (CA)',
    'Surface Winds (BR)',
    'Temperature (BR)',
    'Ceiling (BR)',
    'Visibility (BR)',
    'Wind Gust (BR)',
    '80m Winds (BR)',
    'UK',
    'Camera Network',
    'Vertical Profile',
    'Aviation Weather',
    'Hazards',
    'PIREP',
    'GAIRMET',
    'SIGMET',
    'METAR',
    'TAF',
    'Maps',
    'Road',
    'Satellite',
    'Aeronautical',
    'Live Forecaster',
    'WAFS-Icing',
    'WAFS-Turbulence'
  ]
  const { user } = useAuth0();
  // const userId = useSelector((state: RootState) => state.user.id)
  const userId = user?.sub;
  const [showMarker, setShowMarker] = useState(true)
  const locations = useSelector((state: RootState) => state.user.locations)
  const selectedItemsIdArray = useSelector(
    (state: RootState) => state.sideNav.selectedItemIds
  )
  const dispatch = useDispatch()

  const menuCallback = (extraData: any) => {
    return (menuItem: IMenuItem, event?: SyntheticEvent) => {
      dispatch(setDefaultLocation({ ...extraData.item }))
    }
  }

  useEffect(() => {
    if (invalidLocations.includes(userLocation)) {
      setErrorMessage('Invalid Location Name')
    } else {
      setErrorMessage('')
    }
  }, [userLocation])

  useEffect(() => {
    setShowMarker(true)
  }, [position])

  const handleLocationSubmit = async () => {
    setShowMarker(false)
    if (position.lat < -180) {
      position.lat += 360
    }
    if (position.lng < -180) {
      position.lng += 360
    }
    if (position.lat > 180) {
      position.lat -= 360
    }
    if (position.lng > 180) {
      position.lng -= 360
    }
    try {
      for (const loc in locations) {
        if (selectedItemsIdArray.includes('locations-' + locations[loc].name)) {
          dispatch(selectedItemIds('locations-' + locations[loc].name))
        }
      }
      if (!selectedItemsIdArray.includes('locations-' + userLocation))
        dispatch(selectedItemIds('locations-' + userLocation))
      const newLocationResponse = await dispatch(
        addNewUserLocation({
          latitude: position.lat,
          longitude: position.lng,
          name: userLocation,
          innerRange: 5,
          outerRange: 20,
          clientOwner: userId,
        })
      )
      if (newLocationResponse.type.endsWith('rejected')) {
        throw new Error('Location addition failed')
      }
      const newLocationId = newLocationResponse?.payload?.id // needed for delete component
      const newLocationName = newLocationResponse?.payload?.name // for display
      dispatch(hideAddLocationPopup())
      const newItem = {
        id: 'locations-' + userLocation,
        label: userLocation,
        link: '',
        icon: marker,
        specialComponent: (
          <DeleteLocation id={newLocationId} menuIdString={userLocation} />
        ),
        callback: menuCallback({
          item: {
            latitude: position.lat,
            longitude: position.lng,
            name: newLocationName,
            innerRange: 5,
            outerRange: 20,
            clientOwner: userId,
          },
        }),
        multiSelect: false,
        subMenu: [],
      }

      // dispatch(prependMenuItem({ item: newItem, parentId: 'location-menu' }))
      dispatch(appendLocationMenu({ item: newItem, parentId: 'location-menu' }))
      dispatch(clearSelectedItems())
      dispatch(selectedItems('Location'))
      dispatch(selectedItems(['location-menu', 'locations-' + newLocationName]))
      const newSelection = [...store.getState().sideNav.selectedItemIds]
      if (newSelection.includes('add-location')) {
        newSelection.splice(newSelection.indexOf('add-location'), 1)
        dispatch(setSelectedItemIds(newSelection))
      }
    } catch (e) {
      errorStatus.addMessage('Add Location: ' + e, 400, 'error', true)
      dispatch(hideAddLocationPopup())
      dispatch(deselectMenuItemById('add-location'))
    }
  }

  const eventHandlers = {
    add: (e: L.LeafletEvent) => {
      markerRef.current = e.target
      e.target.openPopup()
    },
    popupclose: () => {
      setShowMarker(false)
    },
  }

  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.openPopup()
    }
  }, [])
  return (
    <>
      {showMarker && (
        <Marker
          key="location-marker"
          position={position}
          eventHandlers={eventHandlers}
        >
          <Popup className="location-popup">
            <div style={popupContainerStyles}>
              <div style={inputFieldContainerStyles}>
                <label style={labelStyles} htmlFor="locationName">
                  Location Name
                </label>
                <input
                  style={inputFieldStyles}
                  id="locationName"
                  type="text"
                  placeholder="Location Name"
                  value={userLocation}
                  maxLength="15"
                  onChange={(e) => setUserLocation(e.target.value)}
                />
                {errorMessage && (
                  <div className="text-red-500">{errorMessage}</div>
                )}
              </div>

              <div style={buttonContainerStyles}>
                <button
                  style={
                    errorMessage == '' ? buttonStyles : disabledButtonStyles
                  }
                  onClick={handleLocationSubmit}
                  disabled={errorMessage != ''}
                >
                  Submit
                </button>
              </div>
            </div>
          </Popup>
        </Marker>
      )}
    </>
  )
}
