/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eslint-comments/no-duplicate-disable */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState, useRef } from 'react'
import userIcon from '../../assets/user.svg'
import chevron from '../../assets/chevron.svg'
// import savedView from '../../assets/saved-view.svg'
// import switchAccount from '../../assets/switch-account.svg'
// import securitySettings from '../../assets/security-settings.svg'
// import notification from '../../assets/notification.svg'
// import subscriptionDetails from '../../assets/subscription-details.svg'
import weatherSensorDetails from '../../assets/weather-sensor-details.svg'
import logoutIcon from '../../assets/logout.svg'
import thresholds from '../../assets/thresholds.svg'
import faq from '../../assets/faq.svg'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, resetReduxStore } from '../../store'
import { TWS_V15_URL } from '../../environment/apis.config'
import { userLogoutAction } from '../../Components/Users/UserSlice'
import { initSideMenu, selectMenuItem, selectMenuItemById, setActiveMenuItem } from '../SideNavigation/SideNavigationSlice'
import savedView from '../../assets/saved-view.svg'
import { createSavedView, deleteSavedView, getSavedView, useSavedView } from '../../Components/SavedViews/savedViews'
import { setSelectedItemIds, setSelectedItems } from '../SideNavigation/SideNavigationSlice'
import { setSavedProfile } from '../../Components/SavedViews/savedViewsSlice'
import Trash from '../../assets/trash.svg'
import { duration } from 'moment-timezone'
import RestoreSelection from '../../Components/SavedViews/RestoreSelection'
import useErrorStatus from '../../hooks/UseErrorStatus'
import { baseMapItems, exclusiveItems, toggledItems } from '../SideNavigation/SideNavigation'
// import { logout as logoutAction } from '../../Components/LoginPage/loginSlice'
// import { Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

export default function ProfileMenu() {
  const [show, setShow] = useState(false)
  const [authenticated, setAuthenticated] = useState(false)
  const [isSubDropdownOpen, setisSubDropdownOpen] = useState(true)
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [newProfileName, setNewProfileName] = useState('');
  const selectedItems = useSelector(
    (state: RootState) => state.sideNav.selectedItems
  )
  const selectedItemIds = useSelector(
    (state: RootState) => state.sideNav.selectedItems
  )
  const firstname = useSelector((state: RootState) => state.user.firstname || state.user.name);
  const lastname = useSelector((state: RootState) => state.user.lastname)
  const savedProfiles = useSelector((state: RootState) => state.savedProfile.savedProfiles)
  const signInRef = useRef(null)
  const signUpRef = useRef(null)
  const itemSelected = () => {
    setShow(false)
  }
  const dispatch = useDispatch()
  const { isAuthenticated, logout } = useAuth0()


  // const logout = () => {
  //   localStorage.removeItem('token')
  //   localStorage.removeItem('defaultLocation')
  //   localStorage.removeItem('userId')
  //   localStorage.setItem('logout-event', 'logout'+Math.random())
  //   dispatch(userLogoutAction())
  //   dispatch(initSideMenu())
  //   setAuthenticated(false)
  //   setShow(false)
  // }

  const [confirmViewDelete, setConfirmViewDelete] = useState(false)

  // const token = localStorage.getItem('token')

  // if (token && authenticated === false) {
  //   setAuthenticated(true)
  // }
  const toggleSubDropdown = () => {
    setisSubDropdownOpen(!isSubDropdownOpen)
  };
  const confirmDelete = async (profileId) => {
    let tempSavedProfiles = [...savedProfiles]
    tempSavedProfiles = tempSavedProfiles.filter(element => element.id !== profileId);
    dispatch(setSavedProfile(tempSavedProfiles))
    await deleteSavedView(profileId)
  }

  const handleDelete = async (profileId) => {
    if (confirm('Are you sure you want to delete this profile?')) {
      confirmDelete(profileId)
    }
  };

  const { activateSelectedProducts} = RestoreSelection()
  const errorStatus = useErrorStatus()

  const hasCommonElement = (array1: string[], array2: string[]): boolean => {
    const set2 = new Set(array2);
    return array1.some(item => set2.has(item));
  }  

  const handleSaveNewProfile = async () => {
    if (!newProfileName) {
      errorStatus.addMessage('Name is required for a new profile.', 200, 'error', false, 15000)
      return;
    }
    const savableItems = [ ...exclusiveItems, ...toggledItems, ...baseMapItems,'decision-missioncast']
    if (!hasCommonElement(selectedItemIds, savableItems)) {
      errorStatus.addMessage('Please select at least one product to save a new profile.', 200, 'error', false, 12000)
      return;
    }
    let createdSavedView = await createSavedView(newProfileName,[selectedItems,selectedItemIds])
    let tempSavedProfiles = [...savedProfiles]
    tempSavedProfiles.push(createdSavedView)
    dispatch(setSavedProfile(tempSavedProfiles))
    setNewProfileName('');
    setIsAddingNew(false);
  };

  const handleActivateSavedView = async (profiles) => {
    profiles[1].forEach((item) => {
      dispatch(selectMenuItemById(item))
    })
    dispatch(setSelectedItems(profiles))
    activateSelectedProducts(profiles[1])
  }

  const handleCancelNewProfile = () => {
    setIsAddingNew(false);
    setNewProfileName('');
  }

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })

  const nonAuthenticatedMenu = (
    <div className="absolute block bg-white p-4 top-12 right-1 w-72 z-[99999]">
      <div className="profile-dropdown-content">
        <Link to="/login">
          <div
            ref={signInRef}
            className="py-2 px1 hover:bg-gray-100 hover:cursor-pointer"
            onClick={itemSelected}
          >
            Sign In
          </div>
        </Link>
        <div
          ref={signUpRef}
          className="py-2 px1 hover:bg-gray-100 hover:cursor-pointer"
          onClick={itemSelected}
        >
          Sign Up
        </div>
      </div>
    </div>
  )
  const authenticatedMenu = (
    <div className="absolute block bg-white p-4 top-16 right-1 w-72 z-[99999] shadow-lg">
      <div className="capitalize">
        {firstname} {lastname}
      </div>
      <div>
        <hr className="border-gray-700"/>
      </div>
      <div className="py-2 px1 hover:cursor-pointer"
      >
        <img
          src={savedView}
          alt=""
          className="mr-4 h-6 align-middle float-left"
          style={{ height: '18px' }}
        />
        <p>Saved View</p>
        {isSubDropdownOpen ? (<>
          <div className="py-2 px-4 hover:bg-gray-100">
            {savedProfiles.length < 3 && (isAddingNew ? (
              <div>
                <input
                  type="text"
                  value={newProfileName}
                  onChange={(e) => setNewProfileName(e.target.value)}
                  placeholder="Enter profile name"
                  className="text-input"
                  required
                />
                
                <button onClick={()=>handleSaveNewProfile()} className="ml-4 bg-gray-300 hover:bg-red-700 text-black py-1 px-2 mt-2 rounded">Save</button>
                <button onClick={()=>handleCancelNewProfile()} className="ml-4 bg-gray-300 hover:bg-red-700 text-black py-1 px-2 mt-2 rounded">Cancel</button>
              </div>
            ) : (
              <div onClick={() => setIsAddingNew(true)}>Add New</div>
            ))}
          </div>
          <div>
            {savedProfiles.length > 0 && savedProfiles?.map((element, index) => (
              <div key={index} className="py-2 px-4 hover:bg-gray-100 flex justify-between items-center" onClick={() => {dispatch(setSelectedItems([]));dispatch(setSelectedItems(element.profiles[0]))
                dispatch(setSelectedItemIds([]));dispatch(setSelectedItemIds(element.profiles[1])); setShow(false)}}>
                  {/** 
                   * this is where the profile is selected in the dropdown:
                   */}
                <span onClick={() => {
                    handleActivateSavedView(element.profiles)
                    }}>
                  {element.profileName}
                </span>
                <button
                  className="ml-4 bg-gray-300 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(element.id);
                  }}
                >
                  <img className="w-6 h-6" src={Trash} alt="" />
                </button>
              </div>
            ))}
          </div>
          </>
        ) : (<></>)
         }
        
      </div>  
      <hr className="border-gray-700"/>    
      <Link to="/userprofile" onClick={itemSelected}>
        <div className="py-2 px1 hover:bg-gray-100 hover:cursor-pointer">
          <img
            src={userIcon}
            alt=""
            className="mr-4 h-6 align-middle float-left invert"
            style={{ height: '18px' }}
          />
          <p>Your Profile</p>
        </div>
      </Link>
      {/* <div className="py-2 px1 hover:bg-gray-100 hover:cursor-pointer">
        <img
          src={switchAccount}
          alt=""
          className="mr-4 h-6 align-middle float-left"
          style={{ height: '18px' }}
        />
        <p>Switch Account</p>
      </div> */}
      {/* <div className="py-2 px1 hover:bg-gray-100 hover:cursor-pointer">
        <img
          src={securitySettings}
          alt=""
          className="mr-4 h-6 align-middle float-left"
          style={{ height: '18px' }}
        />
        <p>Security Settings</p>
      </div> */}
      {/* <div className="py-2 px1 hover:bg-gray-100 hover:cursor-pointer">
        <img
          src={notification}
          alt=""
          className="mr-4 h-6 align-middle float-left"
          style={{ height: '18px' }}
        />
        <p>Notification</p>
      </div> */}
      {/* <div className="py-2 px1 hover:bg-gray-100 hover:cursor-pointer">
        <img
          src={subscriptionDetails}
          alt=""
          className="mr-4 h-6 align-middle float-left"
          style={{ height: '18px' }}
        />
        <p>Subscription Details</p>
      </div> */}
      <Link to="/sensordetails" onClick={itemSelected}>
        <div className="py-2 px1 hover:bg-gray-100 hover:cursor-pointer">
          <img
            src={weatherSensorDetails}
            alt=""
            className="mr-4 h-6 align-middle float-left"
            style={{ height: '18px' }}
          />
          <p>Weather Sensor Details</p>
        </div>
      </Link>
      <Link to="/customthresholds" onClick={itemSelected}>
        <div className="py-2 px1 hover:bg-gray-100 hover:cursor-pointer">
          <img
            src={thresholds}
            alt=""
            className="mr-4 h-6 align-middle float-left"
            style={{ height: '18px' }}
          />
          <p>Custom Thresholds</p>
        </div>
      </Link>
      <Link to="/faq" onClick={itemSelected}>
        <div className="py-2 px1 hover:bg-gray-100 hover:cursor-pointer">
          <img
            src={faq}
            alt=""
            className="mr-4 h-6 align-middle float-left"
            style={{ height: '18px' }}
          />
          <p>Frequently Asked Questions</p>
        </div>
      </Link>
      <div>
        <hr />
      </div>
      <Link to="." className="profile-menu-dropdown-item" onClick={logoutWithRedirect}>
        <div className="py-2 px1 hover:bg-gray-100 hover:cursor-pointer">
          <img
            src={logoutIcon}
            alt=""
            className="mr-4 h-6 align-middle float-left"
            style={{ height: '18px' }}
          />
          <p>Logout</p>
        </div>
      </Link>
    </div>
  )

  const handleMenuToggle = () => {
    setShow(!show)
  }

  const dropdownMenu = isAuthenticated ? authenticatedMenu : nonAuthenticatedMenu
  return (
    <>
      <div
        className="self-center border-l border-black border-solid px-4 w-64 cursor-pointer text-white"
        onClick={handleMenuToggle}
      >
        <div>
          <img
            className="mr-4 h-6 align-middle float-left"
            src={userIcon}
            alt=""
          />
        </div>
        <span className="capitalize">{firstname}</span>
        <img
          className="invert float-right mt-2 mr-4 mb-0 ml-4"
          src={chevron}
          alt=""
          style={{ filter: 'brightness(0) invert(1)' }}
        />
      </div>
      {show ? dropdownMenu : <></>}
    </>
  )
}
