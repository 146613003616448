import { FeatureGroup } from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw'
import { useState, useEffect, useRef } from 'react'
import { ControlPosition, LatLng } from 'leaflet'
import 'leaflet-draw/dist/leaflet.draw.css'
import { useDispatch, useSelector } from 'react-redux'
import {
  setDropDown,
  setEvalApiResponse,
  setOptApiResponse,
  setWaypoints,
  setDrawnPolyline,
  routeCastEdit,
} from '../../Components/DecisionProducts/RouteCast/routecastSlice'
import { RootState } from '../../store'

import {
  isPolygon,
  outOfBounds,
  responseOk,
  setDropDownAlert,
  setLocation,
  setLocationType,
} from '../../Components/Alerting/AlertingSlice/AlertingSlice'
import { MenuLabels } from '../../constants/MenuLabels'
interface MapComponentProps {
  selectedSideNav: string
}

let finalJoinedArray = ''

export const DrawComponent = ({ selectedSideNav }: MapComponentProps) => {
  const drawnPolylineRef = useRef<any | null>(null)
  const selectedIndex: number = useSelector(
    (state) => state.rcast.selectedIndex
  )
  const evalApiResponse: any = useSelector(
    (state: RootState) => state.rcast.routes[selectedIndex].evalApiResponse
  )
  const optApiResponse: any = useSelector(
    (state: RootState) => state.rcast.routes[selectedIndex].optApiResponse
  )
  const editRouteCast: any = useSelector(
    (state: RootState) => state.rcast.editRoutecast
  )
  const [drawn, setDrawn] = useState(false)
  const dispatch = useDispatch()
  const drawControlRef = useRef<React.RefObject<any>>(null)
  const selection = 'Create an Alert'
  const [drawnAlert, setDrawnAlert] = useState(false)
  const showRC = useSelector((state: RootState) => state.rcast.showRouteCAST)
  const showAlerts = useSelector(
    (state: RootState) => state.alert.showAlertSETTING
  )
  const selectedItemsVar = useSelector(
    (state: RootState) => state.sideNav.selectedItems
  )
  const defaultLocation = useSelector(
    (state: RootState) => state.user.defaultLocation
  )
  // const selectedItemIds = useSelector(
  //   (state: RootState) => state.sideNav.selectedItems
  // )
  const maxLat = Number(defaultLocation.latitude) + 1.45
  const maxLong = Number(defaultLocation.longitude) + 1.85
  const minLat = Number(defaultLocation.latitude) - 1.45
  const minLong = Number(defaultLocation.longitude) - 1.85
  finalJoinedArray = ''

  useEffect(() => {
    if (evalApiResponse || optApiResponse) {
      if (drawnPolylineRef.current) {
        drawnPolylineRef.current.remove()
      }
      clearDrawnPolyline()
    }
  }, [evalApiResponse, optApiResponse])
  const clearDrawnPolyline = () => {
    if (selectedSideNav === selection) {
      if (drawControlRef.current) {
        const drawControl = drawControlRef.current
        drawControl.leafletElement._toolbars.edit._modes.draw.handler.disable()
        drawControl.leafletElement._toolbars.edit._modes.remove.handler.enable()
        drawControl.leafletElement._toolbars.edit._modes.remove.removeAllLayers()
      }
      dispatch(setLocation(''))
    } else {
      setDrawn(false)
      if (drawControlRef.current) {
        const drawControl = drawControlRef.current
        drawControl.leafletElement._toolbars.edit._modes.draw.handler.disable()
        drawControl.leafletElement._toolbars.edit._modes.remove.handler.enable()
        drawControl.leafletElement._toolbars.edit._modes.remove.removeAllLayers()
      }
    }
    dispatch(isPolygon(false))
  }

  const _onCreate = (e: any) => {
    
    if (selectedSideNav === selection) {
      if (e.layerType == 'marker') {
        const latLngs = e.layer.getLatLng()
        if (
          latLngs.lat < minLat ||
          latLngs.lat > maxLat ||
          latLngs.lng < minLong ||
          latLngs.lng > maxLong
        ) {
          dispatch(outOfBounds(true))
          dispatch(
            responseOk({
              code: 403,
              msg: 'Polygon out of bounds, please edit/create polygon inside given bounding box.',
              valid: true,
            })
          )
        }
        const formattedArray = []
        formattedArray[0] = latLngs.lng
        formattedArray[1] = latLngs.lat
        const result = JSON.stringify(formattedArray)
        finalJoinedArray = result
        dispatch(setLocation(''))
        dispatch(setLocationType(''))
        dispatch(setLocation(finalJoinedArray))
        dispatch(setLocationType('Point'))
        dispatch(setDropDownAlert(true))
        dispatch(isPolygon(false))
      } else if (e.layerType === 'polygon') {
        const latLngs = e.layer.getLatLngs()
        for (const coordinates in latLngs) {
          for (const coordinate in latLngs[coordinates]) {
            if (
              latLngs[coordinates][coordinate].lat < minLat ||
              latLngs[coordinates][coordinate].lat > maxLat ||
              latLngs[coordinates][coordinate].lng < minLong ||
              latLngs[coordinates][coordinate].lng > maxLong
            ) {
              dispatch(outOfBounds(true))
              dispatch(
                responseOk({
                  code: 403,
                  msg: 'Polygon out of bounds, please edit/create polygon inside given bounding box.',
                  valid: true,
                })
              )
              break
            }
          }
        }
        latLngs.forEach((polygon: any[], index: number) => {
          const formattedArray = polygon.map((latLng) => [
            latLng.lng,
            latLng.lat,
          ])
          let formattedPolygon = JSON.stringify(formattedArray)
          if (finalJoinedArray.length > 0) {
            finalJoinedArray = finalJoinedArray.slice(
              0,
              finalJoinedArray.length - 1
            )
            finalJoinedArray += ', '
            formattedPolygon = formattedPolygon.slice(
              1,
              formattedPolygon.length
            )
          }
          finalJoinedArray += `[${formattedPolygon}]`
        })
        dispatch(setLocation(''))
        dispatch(setLocationType(''))
        dispatch(setLocation(finalJoinedArray))
        dispatch(setLocationType('Polygon'))
        dispatch(setDropDown(true))
        dispatch(isPolygon(true))
      } else if (e.layerType == 'polyline') {
        const latLngs = e.layer.getLatLngs()
        const editedCoordinates = latLngs
        for (const coordinates in latLngs) {
          if (
            latLngs[coordinates].lat < minLat ||
            latLngs[coordinates].lat > maxLat ||
            latLngs[coordinates].lng < minLong ||
            latLngs[coordinates].lng > maxLong
          ) {
            dispatch(outOfBounds(true))
            dispatch(
              responseOk({
                code: 403,
                msg: 'Polygon out of bounds, please edit/create polygon inside given bounding box.',
                valid: true,
              })
            )
            break
          }
        }
        const formattedPolyline = latLngs.map(
          (latLng: { lat: any; lng: any }) => [latLng.lng, latLng.lat]
        )
        let result = JSON.stringify(formattedPolyline)

        if (finalJoinedArray.length > 0) {
          finalJoinedArray = finalJoinedArray.slice(
            0,
            finalJoinedArray.length - 1
          )
          finalJoinedArray += ', '
          result = result.slice(1, result.length)
        }
        finalJoinedArray += result

        dispatch(setLocation(''))
        dispatch(setLocationType(''))
        dispatch(setLocation(finalJoinedArray))
        dispatch(setLocationType('LineString'))
        dispatch(setDropDown(true))
        dispatch(isPolygon(false))
      }
      setDrawnAlert(true)
    } else {
      dispatch(routeCastEdit(true))
      clearDrawnPolyline()
      dispatch(setEvalApiResponse(null))
      dispatch(setOptApiResponse(null))
      dispatch(setWaypoints(''))
      dispatch(setDrawnPolyline(e.layer))
      drawnPolylineRef.current = e.layer
      const latLngs = e.layer.getLatLngs()
      if (e.layerType === 'polygon') {
        const joinedArray = latLngs.map((array: any) =>
          array
            .map((latLng: any) => `${latLng.lat},${latLng.lng},10`)
            .join(', ')
        )
        const firstLat = latLngs[0][0].lat
        const firstLng = latLngs[0][0].lng
        const finalJoinedArray = `${joinedArray}, ${firstLat},${firstLng},10`
        dispatch(setWaypoints(''))
        dispatch(setWaypoints(finalJoinedArray))
      } else {
        const wpoints = latLngs.map((latLng: LatLng) => [
          latLng.lat,
          latLng.lng,
        ])
        const waypointsString = wpoints
          .map((wt: string) => `${wt[0]}, ${wt[1]},10`)
          .join(', ')
        dispatch(setWaypoints(''))
        dispatch(setWaypoints(waypointsString))
      }
      setDrawn(true)
      dispatch(setDropDown(true))
    }
  }
  const _onDelete = (e: any) => {
    dispatch(outOfBounds(false))
    if (selectedSideNav === selection) {
      clearDrawnPolyline()
      finalJoinedArray = ''
    } else {
      dispatch(setEvalApiResponse(null))
      dispatch(setOptApiResponse(null))
      dispatch(setWaypoints(''))
      clearDrawnPolyline()
    }
  }

  const _onEdit = (e: any) => {
    const editedLayer = e.layers.getLayers()[0]
    const editedCoordinates = editedLayer.toGeoJSON().geometry.coordinates
    const flattenedCoordinates = editedCoordinates.flat(2)
    const reorderedCoordinates = []
    let outofbound = false
    if (typeof editedCoordinates[0] === 'number') {
      if (
        editedCoordinates[1] < minLat ||
        editedCoordinates[1] > maxLat ||
        editedCoordinates[0] < minLong ||
        editedCoordinates[0] > maxLong
      ) {
        outofbound = true
        dispatch(
          responseOk({
            code: 403,
            msg: 'Polygon out of bounds, please edit/create polygon inside given bounding box.',
            valid: true,
          })
        )
      }
    } else if (typeof editedCoordinates[0][0] === 'number') {
      for (const coordinates in editedCoordinates) {
        if (
          editedCoordinates[coordinates][1] < minLat ||
          editedCoordinates[coordinates][1] > maxLat ||
          editedCoordinates[coordinates][0] < minLong ||
          editedCoordinates[coordinates][0] > maxLong
        ) {
          outofbound = true
          dispatch(
            responseOk({
              code: 403,
              msg: 'Polygon out of bounds, please edit/create polygon inside given bounding box.',
              valid: true,
            })
          )
          break
        }
      }
    } else if (typeof editedCoordinates[0][0][0] === 'number') {
      for (const coordinates in editedCoordinates) {
        for (const coordinate in editedCoordinates[coordinates]) {
          if (
            editedCoordinates[coordinates][coordinate][1] < minLat ||
            editedCoordinates[coordinates][coordinate][1] > maxLat ||
            editedCoordinates[coordinates][coordinate][0] < minLong ||
            editedCoordinates[coordinates][coordinate][0] > maxLong
          ) {
            outofbound = true
            dispatch(
              responseOk({
                code: 403,
                msg: 'Polygon out of bounds, please edit/create polygon inside given bounding box.',
                valid: true,
              })
            )
            break
          }
        }
      }
    }
    dispatch(outOfBounds(outofbound))

    for (let i = 0; i < flattenedCoordinates.length; i += 2) {
      reorderedCoordinates.push(
        flattenedCoordinates[i + 1],
        flattenedCoordinates[i],
        10
      )
    }
    const commaSeparatedString = reorderedCoordinates.join(', ')
    drawnPolylineRef.current = e.layer
    dispatch(setWaypoints(''))
    dispatch(setWaypoints(commaSeparatedString))
    setDrawn(true)
    dispatch(setDropDown(true))
  }
  const editControlOptions: {
    position: ControlPosition
    onCreated: (e: any) => void
    onDeleted: (e: any) => void
    onEdited: (e: any) => void
    draw: {
      rectangle: boolean
      polyline: boolean
      polygon: {
        shapeOptions: {
          fill: boolean
        }
      }
      circle: boolean
      circlemarker: boolean
      marker?: boolean
    }
    edit: {
      edit: boolean
    }
  } = {
    position: 'bottomleft',
    onCreated: _onCreate,
    onDeleted: _onDelete,
    onEdited: _onEdit,
    draw: {
      rectangle: false,
      polyline: true,
      polygon: {
        shapeOptions: {
          fill: false,
        },
      },
      circle: false,
      circlemarker: false,
      marker: false,
    },
    edit: {
      edit: editRouteCast,
    },
  }
  if (selectedSideNav === selection) {
    editControlOptions.draw.marker = true
    editControlOptions.draw.polygon.shapeOptions.fill = true
  }
  return (
    <>
      <div>
        {selectedItemsVar.includes(MenuLabels.ALERTS_CREATE) && (
          <FeatureGroup>
            <EditControl {...editControlOptions} />
          </FeatureGroup>
        )}
        {showRC && selectedItemsVar.includes(MenuLabels.ROUTECAST) && (
          <FeatureGroup>
            <EditControl {...editControlOptions} />
          </FeatureGroup>
        )}
      </div>
    </>
  )
}
